import * as Immutable from 'immutable';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Item } from '../../types';
import { AppState } from '../state';

export const useItemsSelector = (): Immutable.Map<string, Item> => useSelector((state: AppState) => state.items.byId);

export const useItemListSelector = (): Item[] => {
  const itemsMap = useSelector((state: AppState) => state.items.byId);
  return useMemo(() => itemsMap.valueSeq().toArray(), [itemsMap]);
};

export const useItemByIdSelector = (itemId?: string): Item | undefined => {
  const itemsMap = useSelector((state: AppState) => state.items.byId);
  return itemId ? itemsMap.get(itemId) : undefined;
};
