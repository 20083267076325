import { FullTheme } from 'react-native-elements';

//https://coolors.co/fffcf2-ccc5b9-403d39-252422-eb5e28
export const Colors = {
  FloralWhite: '#FFFCF2',
  PaleSilver: '#CCC5B9',
  BlackOlive: '#403D39',
  EerieBlack: '#252422',
  Flame: '#EB5E28',
};

export const AppTheme: Partial<FullTheme> = {
  colors: {
    primary: Colors.Flame,
    secondary: 'green',
  },
  Button: {
    raised: false,
    titleStyle: {
      fontSize: 18,
    },
  },
  ListItem: {
    containerStyle: {
      backgroundColor: Colors.BlackOlive,
    },
    // underlayColor: Colors.Flame,
  },
  ListItemTitle: {
    style: { color: Colors.FloralWhite },
  },
  ListItemSubtitle: {
    style: { color: Colors.FloralWhite },
  },
  Text: {
    style: { color: Colors.FloralWhite },
  },
};

export const GlobalStyles = {
  input: {
    minHeight: 40,
    padding: 10,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: Colors.FloralWhite,
  },
  largeInput: {
    minHeight: 80,
    padding: 10,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: Colors.FloralWhite,
  },
  label: {
    color: Colors.FloralWhite,
    marginTop: 10,
    marginBottom: 6,
  },
};
