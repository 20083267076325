import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  propertyRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkBox: {
    margin: 0,
    justifyContent: 'flex-end',
  },
  textWithLabel: {
    flex: 1,
    flexDirection: 'column',
  },
});
