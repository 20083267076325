import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { ImageSourcePropType, SafeAreaView, StyleSheet, Text, TextInput, TouchableHighlight, View } from 'react-native';
import { Button } from 'react-native-elements';
import { ImageSelectorDialog } from '../components/dialogs/ImageSelectorDialog';
import { ListItem, SelectionListDialog } from '../components/dialogs/SelectionListDialog';
import { ItemImage } from '../components/ItemImage';
import { Avatars } from '../constants';
import { useItemListSelector } from '../redux/selectors/itemsSelector';
import { useAppDispatch } from '../redux/store';
import { InventoriesThunk } from '../redux/thunks/inventoriesThunk';
import { ScreenProps } from '../routes';
import { Colors } from '../theme';
import { Inventory } from '../types';

type Props = ScreenProps<'CreateInventoryScreen'>;

export const CreateInventoryScreen: React.FC<Props> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const [imageSelectorVisible, setImageSelectorVisible] = useState<boolean>(false);
  const [itemSelectorVisible, setItemSelectorVisible] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [image, setImage] = useState<ImageSourcePropType>();
  const [items, setItems] = useState<ListItem[]>([]);

  const allItems = useItemListSelector();

  const canCreate = !!(image && name);

  const onCreateItem = () => {
    if (image && name) {
      const newInventory: Inventory = {
        id: nanoid(),
        name,
        description,
        image,
        slots: items.map(({ key }) => ({ id: nanoid(), itemId: key, count: 1, isEmpty: false })),
      };

      dispatch(InventoriesThunk.save(newInventory));
      navigation.navigate('InventoriesScreen', { type: 'heroes' });
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <TouchableHighlight style={styles.imageSelector} onPress={() => setImageSelectorVisible(true)}>
          {image ? <ItemImage source={image} /> : <Text style={styles.imageLabel}>Select an image </Text>}
        </TouchableHighlight>
        <View>
          <Text style={styles.label}>Name</Text>
          <TextInput style={styles.input} onChangeText={(name: string) => setName(name)} placeholder="Fearless hero" />
        </View>
        <View>
          <Text style={styles.label}>Description</Text>
          <TextInput
            style={[styles.input, { height: 80 }]}
            onChangeText={(description: string) => setDescription(description)}
            multiline={true}
            placeholder="Fearless heroes can be found everywhere in the world, but most of them are already dead"
          />
        </View>
        <View>
          <Text style={{ color: 'white', marginTop: 20 }}>Added {items.length} items</Text>
          <Button title="Add Items" style={{ marginTop: 20 }} onPress={() => setItemSelectorVisible(true)} />
        </View>
        <Button title="Create" style={{ marginTop: 20 }} onPress={onCreateItem} disabled={!canCreate} />
      </View>
      <ImageSelectorDialog
        images={Avatars}
        visible={imageSelectorVisible}
        onRequestClose={() => setImageSelectorVisible(false)}
        onImageSelected={(image: ImageSourcePropType) => setImage(image)}
      />
      <SelectionListDialog
        visible={itemSelectorVisible}
        items={allItems.map(({ id, name, image }) => ({ key: id, text: name, image }))}
        onRequestClose={() => setItemSelectorVisible(false)}
        onSelectPress={(selectedItems) => setItems(selectedItems)}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  content: {
    backgroundColor: Colors.EerieBlack,
    padding: 20,
  },
  imageSelector: {
    width: 100,
    height: 100,
    borderRadius: 5,
    backgroundColor: Colors.BlackOlive,
    borderWidth: 1,
    borderColor: Colors.FloralWhite,
    borderStyle: 'dashed',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageLabel: {
    color: Colors.FloralWhite,
    textAlign: 'center',
  },
  label: {
    color: Colors.FloralWhite,
    marginTop: 12,
    marginBottom: 8,
  },
  input: {
    height: 40,
    padding: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
});
