import * as Immutable from 'immutable';
import { default as React, useState } from 'react';
import { TextInput, View } from 'react-native';
import { CheckBox, Text } from 'react-native-elements';
import { GlobalStyles } from '../../theme';
import { CustomProperty, CustomPropertyType, CustomPropertyValue } from '../../types';
import { styles } from './styles';

type Props = {
  items: CustomProperty[];
  selection: Map<string, CustomPropertyValue>; //TODO: use ISelection
};

export const PropertySelectorGrid: React.FC<Props> = ({ items, selection }) => {
  const [checkedProps, setCheckedProps] = useState<Immutable.Set<string>>(Immutable.Set());

  return (
    <View style={styles.container}>
      {items.map((item) => {
        const editable = checkedProps.has(item.name);

        return (
          <View key={item.name} style={styles.propertyRow}>
            <CheckBox
              containerStyle={styles.checkBox}
              checked={editable}
              onPress={() =>
                setCheckedProps((prev) => (prev.has(item.name) ? prev.remove(item.name) : prev.add(item.name)))
              }
            />
            <View style={styles.textWithLabel}>
              <Text style={GlobalStyles.label}>{item.name}</Text>
              <TextInput
                editable={editable}
                keyboardType={item.type === CustomPropertyType.Number ? 'numeric' : 'default'}
                focusable={editable}
                style={[GlobalStyles.input, { backgroundColor: editable ? 'white' : 'gray' }]}
                onChangeText={(value: string) => {
                  selection.set(item.name, value);
                }}
              />
            </View>
          </View>
        );
      })}
    </View>
  );
};
