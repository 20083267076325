import * as Immutable from 'immutable';
import { createReducer, describeAction } from 'ts-describe-action';
import { Inventory } from '../../types';
import { InventoriesState } from '../state';
import { ActionTypes } from './actionTypes';

const save = describeAction('SAVE_INVENTORY', (prev: InventoriesState, inventory: Inventory) => {
  const isNew = !prev.byId.get(inventory.id);

  return {
    ...prev,
    byId: prev.byId.set(inventory.id, inventory),
    allIds: isNew ? [...prev.allIds, inventory.id] : prev.allIds,
  };
});

const remove = describeAction('REMOVE_INVENTORY', (prev: InventoriesState, inventoryId: string) => ({
  ...prev,
  byId: prev.byId.remove(inventoryId),
  allIds: prev.allIds.filter((id) => id !== inventoryId),
}));

const load = describeAction('LOAD_INVENTORIES', (prev: InventoriesState, items: Inventory[]) => ({
  ...prev,
  byId: Immutable.Map<string, Inventory>(items.map((item) => [item.id, item])),
}));

export const InventoryAction = {
  save,
  remove,
  load,
};

export type InventoryActionType = ActionTypes<typeof InventoryAction>;

export const inventoriesReducer = createReducer(Object.values(InventoryAction), {
  byId: Immutable.Map<string, Inventory>(),
  allIds: [],
});
