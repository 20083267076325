import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { Button } from 'react-native-elements';
import { Inventory } from '../../components/Inventory';
import { ListItem, SelectionListDialog } from '../../components/dialogs/SelectionListDialog';
import { SLOTS_PER_ROW } from '../../constants';
import { useInventoryByIdSelector } from '../../redux/selectors/inventoriesSelector';
import { useItemListSelector } from '../../redux/selectors/itemsSelector';
import { useAppDispatch } from '../../redux/store';
import { InventoriesThunk } from '../../redux/thunks/inventoriesThunk';
import { ScreenProps } from '../../routes';
import { styles } from './styles';

type Props = ScreenProps<'InventoryScreen'>;

export const InventoryScreen: React.FC<Props> = ({ route, navigation }) => {
  const { inventoryId } = route.params;
  const allItems = useItemListSelector();
  const inventory = useInventoryByIdSelector(inventoryId);

  const dispatch = useAppDispatch();
  const [itemSelectorVisible, setItemSelectorVisible] = useState<boolean>(false);

  const onItemsSelected = async (selectedItems: ListItem[]) => {
    dispatch(
      InventoriesThunk.addSlots(
        inventoryId,
        selectedItems.map((item) => ({
          id: nanoid(),
          itemId: item.key,
          isEmpty: false,
          count: 1,
        })),
      ),
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        {inventory ? (
          <Inventory
            slots={inventory.slots}
            itemsPerRow={SLOTS_PER_ROW}
            onAddNewSlot={() => setItemSelectorVisible(true)}
            onRemoveSlot={(slot) => dispatch(InventoriesThunk.removeSlot(inventoryId, slot.id))}
          />
        ) : null}
      </View>
      <Button
        title="Remove hero"
        style={styles.removeButton}
        onPress={() => {
          dispatch(InventoriesThunk.remove(inventoryId));
          navigation.popToTop();
        }}
      />
      <SelectionListDialog
        visible={itemSelectorVisible}
        items={allItems.map(({ id, name, image }) => ({ key: id, text: name, image }))}
        onRequestClose={() => setItemSelectorVisible(false)}
        onSelectPress={onItemsSelected}
      />
    </SafeAreaView>
  );
};
