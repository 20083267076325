import React from 'react';
import { Text } from 'react-native-elements';
import { CustomProperty, CustomPropertyValue } from '../types';
import { Stack } from './Stack';

type Props = {
  property: CustomProperty;
  propertyValue: CustomPropertyValue;
};

export const CustomPropertyRow: React.FC<Props> = ({ property, propertyValue }) => {
  return (
    <Stack isHorizontal={true}>
      <Text>{property.name}</Text>
      <Text>{propertyValue}</Text>
    </Stack>
  );
};
