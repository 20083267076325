import { default as React } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Avatar } from 'react-native-elements';
import { useInventoryByIdSelector } from '../redux/selectors/inventoriesSelector';
import { Colors } from '../theme';

type Props = {
  inventoryId: string;
};

export const InventoryHeader: React.FC<Props> = ({ inventoryId }) => {
  const inventory = useInventoryByIdSelector(inventoryId);

  return inventory ? (
    <View style={styles.container}>
      <Avatar rounded={true} source={inventory.image} size="large" />
      <Text style={styles.heroName}>{inventory.name}</Text>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  heroName: {
    color: Colors.PaleSilver,
  },
});
