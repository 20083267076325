import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, TouchableHighlight } from 'react-native';
import { Overlay } from 'react-native-elements';
import { Colors } from '../../theme';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
};

export const ModalDialog: React.FC<Props> = ({ visible, children, onRequestClose }) => (
  <Overlay
    animationType="none"
    fullScreen={false}
    isVisible={visible}
    overlayStyle={styles.content}
    backdropStyle={styles.backdrop}
    onBackdropPress={onRequestClose}>
    {children}
    <TouchableHighlight style={styles.closeButton} onPress={onRequestClose}>
      <Ionicons name="close" size={20} color="white" style={styles.closeIcon} />
    </TouchableHighlight>
  </Overlay>
);

const styles = StyleSheet.create({
  content: {
    backgroundColor: Colors.BlackOlive,
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignSelf: 'center',
    maxWidth: 400, //TODO: calculate size in onLayout
    maxHeight: 500, //TODO: calculate size in onLayout
    padding: 10,
  },
  backdrop: {
    backgroundColor: 'black',
    opacity: 0.5,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: Colors.EerieBlack,
    opacity: 0.8,
    borderRadius: 30,
    width: 30,
    height: 30,
    alignSelf: 'center',
    justifyContent: 'center',
    elevation: 2,
  },
  closeIcon: {
    textAlign: 'center',
  },
});
