import { ImageRequireSource } from 'react-native';

export const MIN_SLOTS_ROWS = 7;
export const SLOTS_PER_ROW = 5;

export const Avatars: ImageRequireSource[] = [
  require('../assets/avatars/avatar1.png'),
  require('../assets/avatars/avatar2.png'),
  require('../assets/avatars/avatar3.png'),
  require('../assets/avatars/avatar4.png'),
  require('../assets/avatars/avatar5.png'),
];

export const ItemImageList: ImageRequireSource[] = [
  require('../assets/items/item1.png'),
  require('../assets/items/item2.png'),
  require('../assets/items/item3.png'),
  require('../assets/items/item4.png'),
  require('../assets/items/item5.png'),
  require('../assets/items/item6.png'),
  require('../assets/items/item7.png'),
  require('../assets/items/item8.png'),
  require('../assets/items/item9.png'),
  require('../assets/items/item10.png'),
  require('../assets/items/item11.png'),
];
