import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useAppDispatch } from '../redux/store';
import { CustomPropertiesThunk } from '../redux/thunks/customPropertiesThunk';
import { InventoriesThunk } from '../redux/thunks/inventoriesThunk';
import { ItemsThunk } from '../redux/thunks/itemsThunk';
import { RootStackParamList, ScreenProps } from '../routes';
import { CreateInventoryScreen } from '../screens/CreateInventoryScreen';
import { CreateItemScreen } from '../screens/CreateItemScreen';
import { InventoriesScreen } from '../screens/InventoriesScreen';
import { InventoryScreen } from '../screens/Inventory/InventoryScreen';
import { ItemsCollectionScreen } from '../screens/ItemsScreen';
import { MainScreen } from '../screens/MainScreen';
import { Colors } from '../theme';
import { InventoryHeader } from './InventoryHeader';

const Stack = createStackNavigator<RootStackParamList>();

//https://reactnavigation.org/blog/2020/05/16/web-support/
const linking: LinkingOptions = {
  enabled: true,
  prefixes: [],
  config: {
    initialRouteName: 'MainScreen',
    screens: {
      MainScreen: '',
      ItemsScreen: 'all_items',
      CreateItemScreen: 'create_item',
      InventoriesScreen: 'inventories/:type',
      InventoryScreen: 'inventory/:inventoryId',
      CreateInventoryScreen: 'create_inventory',
    },
  },
};

const commonScreenOptions: StackNavigationOptions = {
  headerStyle: { backgroundColor: Colors.BlackOlive },
  headerTitleStyle: { color: Colors.FloralWhite },
  headerTintColor: Colors.FloralWhite,
  cardStyle: { backgroundColor: Colors.EerieBlack },
  //gestureEnabled: true,
  gestureResponseDistance: { horizontal: 1000 }, //can drag 1000px from the left of the screen
};

const inventoryScreenOptions = (props: ScreenProps<'InventoryScreen'>): StackNavigationOptions => ({
  headerStyle: {
    height: 150,
    backgroundColor: Colors.BlackOlive,
  },
  headerBackTitleVisible: false,
  headerTitle: () => <InventoryHeader inventoryId={props.route.params.inventoryId} />,
});

const styles = StyleSheet.create({
  container: {
    height: Platform.OS === 'web' ? '100vh' : '100%',
    borderWidth: 1, //NOTE: if we do not set border here, height 100% will not be applied and screen in web will occupy more then 100% height
    borderColor: Colors.BlackOlive,
  },
});

export const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(ItemsThunk.load());
    dispatch(InventoriesThunk.load());
    dispatch(CustomPropertiesThunk.load());
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator initialRouteName="MainScreen" screenOptions={commonScreenOptions}>
          <Stack.Screen name="MainScreen" component={MainScreen} options={{ headerShown: false }} />
          <Stack.Screen name="InventoryScreen" component={InventoryScreen} options={inventoryScreenOptions} />
          <Stack.Screen
            name="CreateItemScreen"
            component={CreateItemScreen}
            options={{ headerTitle: 'Create an item' }}
          />
          <Stack.Screen name="ItemsScreen" component={ItemsCollectionScreen} options={{ headerTitle: 'All Items' }} />
          <Stack.Screen name="InventoriesScreen" component={InventoriesScreen} />
          <Stack.Screen
            name="CreateInventoryScreen"
            component={CreateInventoryScreen}
            options={{ headerTitle: 'Create inventory' }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  );
};
