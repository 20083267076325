import * as Immutable from 'immutable';
import { createReducer, describeAction } from 'ts-describe-action';
import { Item } from '../../types';
import { ItemsState } from '../state';
import { ActionTypes } from './actionTypes';

const load = describeAction('LOAD_ITEMS', (prev: ItemsState, items: Item[]) => ({
  ...prev,
  byId: Immutable.Map<string, Item>(items.map((item) => [item.id, item])),
  allIds: items.map(({ id }) => id),
}));

const save = describeAction('SAVE_ITEM', (prev: ItemsState, item: Item) => {
  const isNew = !prev.byId.get(item.id);

  return {
    ...prev,
    byId: prev.byId.set(item.id, item),
    allIds: isNew ? [...prev.allIds, item.id] : prev.allIds,
  };
});

const remove = describeAction('REMOVE_ITEM', (prev: ItemsState, itemId: string) => ({
  ...prev,
  byId: prev.byId.remove(itemId),
  allIds: prev.allIds.filter((id) => id !== itemId),
}));

export const ItemAction = {
  load,
  save,
  remove,
};

export type ItemActionType = ActionTypes<typeof ItemAction>;

export const itemsReducer = createReducer(Object.values(ItemAction), {
  byId: Immutable.Map<string, Item>(),
  allIds: [],
});
