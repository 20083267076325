import { AppDispatch, AppState, AppThunkAction, MutableThunkState } from '../state';
import { ItemAction } from '../reducers/itemsReducer';
import { Item } from '../../types';

const save = (item: Item): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { itemsStorage }: MutableThunkState,
): Promise<void> => {
  try {
    await itemsStorage.saveItemAsync(item);
    dispatch(ItemAction.save(item));
  } catch (e) {
    console.error(e);
  }
};

const remove = (itemId: string): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { itemsStorage }: MutableThunkState,
): Promise<void> => {
  try {
    await itemsStorage.removeItemAsync(itemId);
    dispatch(ItemAction.remove(itemId));
  } catch (e) {
    console.error(e);
  }
};

const load = (): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { itemsStorage }: MutableThunkState,
): Promise<void> => {
  try {
    const items = await itemsStorage.getAllItemsAsync();
    dispatch(ItemAction.load(items));
  } catch (e) {
    console.error(e);
  }
};

export const ItemsThunk = {
  load,
  save,
  remove,
};
