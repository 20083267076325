import { default as React, useState } from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { ItemDescriptionDialog } from '../components/dialogs/ItemDescriptionDialog';
import { ListComponent } from '../components/ListComponent';
import { useItemListSelector } from '../redux/selectors/itemsSelector';
import { useAppDispatch } from '../redux/store';
import { ItemsThunk } from '../redux/thunks/itemsThunk';
import { ScreenProps } from '../routes';
import { Item } from '../types';

type Props = ScreenProps<'ItemsScreen'>;

export const ItemsCollectionScreen: React.FC<Props> = ({ navigation }) => {
  const items = useItemListSelector();
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = useState<Item | undefined>();

  const onRemoveItem = (item: Item) => dispatch(ItemsThunk.remove(item.id));

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <ListComponent
          data={items}
          onItemPress={(item) => setSelectedItem(item)}
          onAddNew={() => navigation.push('CreateItemScreen')}
          showRemoveButton={true}
          onRemove={onRemoveItem}
        />
      </View>
      <ItemDescriptionDialog
        visible={!!selectedItem}
        itemId={selectedItem?.id}
        onRequestClose={() => setSelectedItem(undefined)}
        onRemove={() => selectedItem && onRemoveItem(selectedItem)}
        removeTitle="Destroy item"
      />
    </SafeAreaView>
  );
};

//https://medium.com/reactbrasil/react-native-set-different-colors-on-top-and-bottom-in-safeareaview-component-f008823483f3
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    margin: 10,
  },
});
