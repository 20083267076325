import { AppDispatch, AppState, AppThunkAction, MutableThunkState } from '../state';
import { InventoryAction } from '../reducers/inventoriesReducer';
import { Inventory, Slot } from '../../types';
import { nanoid } from 'nanoid';
import { MIN_SLOTS_ROWS, SLOTS_PER_ROW } from '../../constants';

const save = (inventory: Inventory): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { inventoriesStorage }: MutableThunkState,
): Promise<void> => {
  try {
    const newInventory = { ...inventory, slots: normalizeEmptySlots([...inventory.slots]) };

    await inventoriesStorage.saveItemAsync(newInventory);
    dispatch(InventoryAction.save(newInventory));
  } catch (e) {
    console.error(e);
  }
};

const remove = (inventoryId: string): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { inventoriesStorage }: MutableThunkState,
): Promise<void> => {
  try {
    await inventoriesStorage.removeItemAsync(inventoryId);
    dispatch(InventoryAction.remove(inventoryId));
  } catch (e) {
    console.error(e);
  }
};

const load = (): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { inventoriesStorage }: MutableThunkState,
): Promise<void> => {
  try {
    const inventories: Inventory[] = await inventoriesStorage.getAllItemsAsync();
    dispatch(InventoryAction.load(inventories));
  } catch (e) {
    console.error(e);
  }
};

const addSlots = (inventoryId: string, slots: Slot[]): AppThunkAction => async (
  dispatch: AppDispatch,
  getState: () => AppState,
): Promise<void> => {
  const inventory = getState().inventories.byId.get(inventoryId);

  if (!inventory) {
    throw new Error(`Cannot find inventory with id=${inventoryId}`);
  }

  const notEmptySlots: Slot[] = inventory.slots.concat(slots).filter((s) => !s.isEmpty);
  const newSlots = normalizeEmptySlots(notEmptySlots);

  await dispatch(save({ ...inventory, slots: newSlots }));
};

const removeSlot = (inventoryId: string, slotId: string): AppThunkAction => async (
  dispatch: AppDispatch,
  getState: () => AppState,
): Promise<void> => {
  const inventory = getState().inventories.byId.get(inventoryId);

  if (!inventory) {
    throw new Error(`Cannot find inventory with id=${inventoryId}`);
  }

  const newSlots = normalizeEmptySlots(inventory.slots.filter((s) => s.id !== slotId && !s.isEmpty));
  await dispatch(save({ ...inventory, slots: newSlots }));
};

const normalizeEmptySlots = (
  slots: Slot[],
  slotsPerRow: number = SLOTS_PER_ROW,
  minRows: number = MIN_SLOTS_ROWS,
): Slot[] => {
  let slotsToAdd = slotsPerRow - (slots.length % slotsPerRow);

  const minSlotsAmount = minRows * slotsPerRow;
  if (slots.length < minSlotsAmount) {
    slotsToAdd = minSlotsAmount - slots.length;
  }

  for (let i = 0; i < slotsToAdd; i++) {
    slots.push({ id: nanoid(), isEmpty: true });
  }

  return slots;
};

// const shrinkEmptySlots = (
//   slots: Slot[],
//   slotsPerRow: number = SLOTS_PER_ROW,
//   minRows: number = MIN_SLOTS_ROWS,
// ): Slot[] => {
//   let slotsToAdd = slotsPerRow - (slots.length % slotsPerRow);

//   const minSlotsAmount = minRows * slotsPerRow;
//   if (slots.length < minSlotsAmount) {
//     slotsToAdd = minSlotsAmount - slots.length;
//   }

//   for (let i = 0; i < slotsToAdd; i++) {
//     slots.push({ id: nanoid(), isEmpty: true });
//   }

//   return slots;
// };

export const InventoriesThunk = {
  load,
  save,
  remove,
  addSlots,
  removeSlot,
};
