import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { createStorage } from '../api/universalStorage';
import { Item, Inventory, CustomProperty } from '../types';
import { accountReducer } from './reducers/accountReducer';
import { inventoriesReducer } from './reducers/inventoriesReducer';
import { customPropertiesReducer } from './reducers/customPropertiesReducer';
import { itemsReducer } from './reducers/itemsReducer';
import { AppActions, AppDispatch, AppState, MutableThunkState } from './state';

const ITEM_PREFIX = '@inv-item-';
const INVENTORY_PREFIX = '@inv-inventory-';
const PROPERTY_PREFIX = '@inv-property-';

export const createReduxStore = (): Store<AppState, AppActions> => {
  const thunkMiddleware = thunk.withExtraArgument<MutableThunkState>({
    itemsStorage: createStorage<Item>(ITEM_PREFIX, ({ id }) => id),
    inventoriesStorage: createStorage<Inventory>(INVENTORY_PREFIX, ({ id }) => id),
    propertiesStorage: createStorage<CustomProperty>(PROPERTY_PREFIX, ({ name }) => name),
  });

  const middlewares = applyMiddleware(thunkMiddleware);

  const rootReducer = combineReducers<AppState, AppActions>({
    account: accountReducer,
    inventories: inventoriesReducer,
    items: itemsReducer,
    customProperties: customPropertiesReducer,
  });

  return createStore(rootReducer, middlewares);
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
