import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Icon, Image } from 'react-native-elements';
import { useItemByIdSelector } from '../redux/selectors/itemsSelector';
import { Colors } from '../theme';
import { Slot } from '../types';

type Props = {
  slotSize: number;
  onPress?: () => void;
  slot: Slot;
};

export const ItemSlot: React.FC<Props> = ({ slot, slotSize, onPress }) => {
  const item = useItemByIdSelector(slot.isEmpty ? '' : slot.itemId); //TODO: do I want to use selectors here?
  const count = slot.isEmpty ? 0 : slot.count;
  const imageSize = 0.8 * slotSize;

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.container, { width: slotSize, height: slotSize }]}>
        <View style={styles.imageFrame}>
          {item ? (
            <Image style={[styles.image, { width: imageSize, height: imageSize }]} source={item.image} />
          ) : (
            <Icon name="add" type="fontawesome" color={Colors.EerieBlack} />
          )}
          {count > 1 && <Text style={styles.itemCount}>{count}</Text>}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.BlackOlive,
    borderColor: Colors.EerieBlack,
    borderWidth: 1,
  },
  itemCount: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: Colors.PaleSilver,
    textShadowColor: 'black',
    textShadowRadius: 2,
    fontSize: 18,
    fontWeight: '700',
    paddingHorizontal: 4,
  },
  imageFrame: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    resizeMode: 'cover',
  },
});
