import React from 'react';
import { useCustomPropertySelector } from '../redux/selectors/customPropertiesSelector';
import { CustomPropertyValue } from '../types';
import { CustomPropertyRow } from './CustomPropertyRow';

type Props = {
  propertyId: string;
  propertyValue: CustomPropertyValue;
};

export const CustomPropertyRowRedux: React.FC<Props> = ({ propertyId, propertyValue }) => {
  const customProperty = useCustomPropertySelector(propertyId);

  console.log({ customProperty });

  return customProperty ? <CustomPropertyRow property={customProperty} propertyValue={propertyValue} /> : null;
};
