import React from 'react';
import { ImageSourcePropType, StyleSheet, Image } from 'react-native';

type Props = {
  source: ImageSourcePropType;
};

export const ItemImage: React.FC<Props> = ({ source }) => (
  <Image style={styles.image} source={source} width={100} height={100} />
);

const styles = StyleSheet.create({
  image: {
    width: 100,
    height: 100,
  },
});
