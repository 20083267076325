import { CustomProperty } from '../../types';
import { CustomPropertiesAction } from '../reducers/customPropertiesReducer';
import { AppDispatch, AppState, AppThunkAction, MutableThunkState } from '../state';

const save = (prop: CustomProperty): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { propertiesStorage }: MutableThunkState,
): Promise<void> => {
  try {
    await propertiesStorage.saveItemAsync(prop);
    dispatch(CustomPropertiesAction.save(prop));
  } catch (e) {
    console.error(e);
  }
};

const remove = (propName: string): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { propertiesStorage }: MutableThunkState,
): Promise<void> => {
  try {
    await propertiesStorage.removeItemAsync(propName);
    dispatch(CustomPropertiesAction.remove(propName));
  } catch (e) {
    console.error(e);
  }
};

const load = (): AppThunkAction => async (
  dispatch: AppDispatch,
  _getState: () => AppState,
  { propertiesStorage }: MutableThunkState,
): Promise<void> => {
  try {
    const properties = await propertiesStorage.getAllItemsAsync();
    dispatch(CustomPropertiesAction.load(properties));
  } catch (e) {
    console.error(e);
  }
};

export const CustomPropertiesThunk = {
  load,
  save,
  remove,
};
