import { createReducer, describeAction } from 'ts-describe-action';
import { ActionTypes } from './actionTypes';

export type AccountState = {
  accountName: string;
};

const setAccountName = describeAction('SET_ACCOUNT_NAME', (prev: AccountState, accountName: string) => ({
  ...prev,
  accountName,
}));

export const AccountActions = {
  setAccountName,
};

export type AccountActionsType = ActionTypes<typeof AccountActions>;

export const accountReducer = createReducer(Object.values(AccountActions), {
  accountName: 'unknown',
});
