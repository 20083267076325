import { StyleSheet } from 'react-native';
import { Colors } from '../../../theme';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.BlackOlive,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignSelf: 'center',
    padding: 10,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  backdrop: {
    backgroundColor: 'black',
    opacity: 0.5,
  },
  content: {
    marginBottom: 20,
  },
  closeButton: {
    backgroundColor: Colors.EerieBlack,
    opacity: 0.8,
    borderRadius: 30,
    width: 30,
    height: 30,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    elevation: 2,
  },
  closeIcon: {
    textAlign: 'center',
  },
});
