import { StyleSheet } from 'react-native';
import { Colors } from '../../theme';

export const styles = StyleSheet.create({
  container: { flex: 1 },
  content: {
    backgroundColor: Colors.EerieBlack,
    padding: 20,
  },
  imageSelector: {
    width: 100,
    height: 100,
    borderRadius: 5,
    backgroundColor: Colors.BlackOlive,
    borderWidth: 1,
    borderColor: Colors.FloralWhite,
    borderStyle: 'dashed',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageLabel: {
    color: Colors.FloralWhite,
    textAlign: 'center',
  },
  label: {
    color: Colors.FloralWhite,
    marginTop: 12,
    marginBottom: 8,
  },
  input: {
    height: 40,
    padding: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
});
