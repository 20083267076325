import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { TouchableHighlight, View } from 'react-native';
import { Overlay } from 'react-native-elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { styles } from './styles';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
};

export const ActionSheet: React.FC<Props> = ({ visible, children, onRequestClose }) => {
  const { bottom } = useSafeAreaInsets();

  return (
    <Overlay
      animationType="slide"
      fullScreen={false}
      isVisible={visible}
      overlayStyle={[styles.container, { paddingBottom: bottom }]}
      backdropStyle={styles.backdrop}
      onBackdropPress={onRequestClose}>
      <TouchableHighlight style={styles.closeButton} onPress={onRequestClose}>
        <Ionicons name="close" size={20} color="white" style={styles.closeIcon} />
      </TouchableHighlight>
      <View style={styles.content}>{children}</View>
    </Overlay>
  );
};
