import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Inventory } from '../../types';
import { AppState } from '../state';

/**
 * Selects Hero by id
 * @param inventoryId Id of hero to return
 * @returns Inventory object associated with inventoryId or undefined if there is no inventory with provided id
 */
export const useInventoryByIdSelector = (inventoryId: string): Inventory | undefined =>
  useSelector((state: AppState) => state.inventories.byId.get(inventoryId));

export const useInventoriesSelector = (): Inventory[] => {
  const inventoriesMap = useSelector((state: AppState) => state.inventories.byId);
  return useMemo(() => inventoriesMap.valueSeq().toArray(), [inventoriesMap]);
};
