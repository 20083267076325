import React, { useEffect, useState } from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Avatar, Button, ListItem } from 'react-native-elements';
import { FlatList } from 'react-native-gesture-handler';
import { Colors } from '../../theme';
import { ModalDialog } from './ModalDialog';

export type ListItem = {
  key: string;
  text: string;
  image: ImageSourcePropType;
};

type SelectableItem = {
  isSelected: boolean;
  item: ListItem;
};

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  items: ListItem[];
  selectButtonText?: string;
  onSelectPress: (selectedItems: ListItem[]) => void;
};

export const SelectionListDialog: React.FC<Props> = ({
  items,
  selectButtonText,
  visible,
  onSelectPress,
  onRequestClose,
}) => {
  const [selectableItems, setSelectableItems] = useState<SelectableItem[]>([]);

  useEffect(() => {
    setSelectableItems(items.map((item) => ({ item, isSelected: false })));
  }, [items]);

  const onItemPress = (selectable: SelectableItem) => {
    setSelectableItems((prev) =>
      prev.map((item) => (item === selectable ? { ...item, isSelected: !item.isSelected } : item)),
    );
  };

  const onSelectButtonPress = () => {
    const onlySelectedItems = selectableItems.filter((item) => item.isSelected).map(({ item }) => item);
    onSelectPress(onlySelectedItems);
    onRequestClose();
  };

  const hasSelectedItems = selectableItems.some((i) => i.isSelected);

  const createListItem = (selectableItem: SelectableItem) => {
    const { item, isSelected } = selectableItem;
    const selectedStyle = { borderColor: isSelected ? Colors.Flame : Colors.EerieBlack };

    return (
      <ListItem style={[styles.listItem, selectedStyle]} key={item.key} onPress={() => onItemPress(selectableItem)}>
        <Avatar rounded={false} source={item.image} size="small" />
        <ListItem.Content>
          <ListItem.Title>{item.text}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
    );
  };

  return (
    <ModalDialog visible={visible} onRequestClose={onRequestClose}>
      <View style={styles.content}>
        <FlatList
          data={selectableItems}
          keyExtractor={({ item }) => item.key}
          renderItem={({ item }) => createListItem(item)}
        />
        <Button
          title={selectButtonText ?? 'Select'}
          style={styles.button}
          onPress={onSelectButtonPress}
          disabled={!hasSelectedItems}
        />
      </View>
    </ModalDialog>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    width: 300,
    margin: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  button: {
    marginVertical: 10,
  },
  listItem: {
    margin: 5,
    borderWidth: 2,
    borderColor: Colors.Flame,
  },
  image: {
    width: 30,
    height: 30,
    margin: 5,
    borderRadius: 5,
  },
  text: {
    color: Colors.FloralWhite,
    fontSize: 15,
  },
});
