import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { ThemeProvider } from 'react-native-elements';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { createReduxStore } from '../redux/store';
import { AppTheme, Colors } from '../theme';
import { AppRoutes } from './AppRoutes';

export const AppContainer: React.FC = () => (
  <Provider store={createReduxStore()}>
    <SafeAreaProvider style={{ backgroundColor: Colors.EerieBlack }}>
      <ThemeProvider theme={AppTheme}>
        <StatusBar style="light" />
        <AppRoutes />
      </ThemeProvider>
    </SafeAreaProvider>
  </Provider>
);
