import React from 'react';
import { FlatList, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Avatar, Button, ListItem } from 'react-native-elements';
import { Colors } from '../theme';

type CustomItem = {
  id: string;
  name: string;
  description?: string;
  image: ImageSourcePropType;
};

type Props<T> = {
  onAddNew: () => void;
  onRemove?: (item: T) => void;
  showRemoveButton?: boolean;
  onItemPress: (item: T) => void;
  data: ReadonlyArray<T>;
};

export const ListComponent = <T extends CustomItem>(props: Props<T>): JSX.Element => {
  const { data, showRemoveButton, onAddNew, onRemove, onItemPress } = props;

  return (
    <FlatList
      ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
      data={data}
      renderItem={({ item }) => (
        <ListItem
          key={item.id}
          bottomDivider={false}
          onPress={() => onItemPress(item)}
          containerStyle={styles.item}
          underlayColor={Colors.EerieBlack}>
          <Avatar rounded={true} source={item.image} size="medium" />
          <ListItem.Content>
            <ListItem.Title>{item.name}</ListItem.Title>
            <ListItem.Subtitle>{item.description}</ListItem.Subtitle>
          </ListItem.Content>
          {showRemoveButton ? (
            <Button title="Remove" onPress={() => onRemove && onRemove(item)} />
          ) : (
            <ListItem.Chevron />
          )}
        </ListItem>
      )}
      ListFooterComponent={<Button title="Add new" style={styles.addButton} onPress={onAddNew} />}
    />
  );
};

const styles = StyleSheet.create({
  item: {
    borderRadius: 5,
    backgroundColor: Colors.BlackOlive,
    padding: 10,
  },
  itemSeparator: { width: 10, height: 10 },
  addButton: { marginVertical: 10 },
});
