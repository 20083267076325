import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ScreenProps } from '../routes';

type Props = ScreenProps<'MainScreen'>;

export const MainScreen: React.FC<Props> = ({ navigation }) => {
  return (
    <SafeAreaView>
      <View style={styles.container}>
        <Button
          title="See all heroes"
          style={styles.button}
          onPress={() => navigation.push('InventoriesScreen', { type: 'heroes' })}
        />
        <Button title="See all items" style={styles.button} onPress={() => navigation.push('ItemsScreen')} />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    margin: 10,
  },
  button: {
    marginVertical: 10,
  },
});
