import AsyncStorage from '@react-native-async-storage/async-storage';

export type UniversalStorage<T> = {
  saveItemAsync: (item: T) => Promise<void>;
  getItemAsync: (itemId: string) => Promise<T | undefined>;
  getAllItemsAsync: () => Promise<T[]>;
  removeItemAsync: (itemId: string) => Promise<void>;
};

export const createStorage = <T>(savePrefix: string, idSelector: (item: T) => string): UniversalStorage<T> => {
  const getItemKey = (itemId: string) => `${savePrefix}${itemId}`;

  const saveItemAsync = (item: T): Promise<void> => {
    const itemId = idSelector(item);
    return AsyncStorage.setItem(getItemKey(itemId), JSON.stringify(item));
  };

  const getItemAsync = async (itemId: string): Promise<T | undefined> => {
    const jsonValue = await AsyncStorage.getItem(getItemKey(itemId));
    return jsonValue != null ? JSON.parse(jsonValue) : undefined;
  };

  const removeItemAsync = async (itemId: string): Promise<void> => {
    return AsyncStorage.removeItem(getItemKey(itemId));
  };

  const getAllItemsAsync = async (): Promise<T[]> => {
    const keys: string[] = await AsyncStorage.getAllKeys();

    const itemIds = keys.filter((key) => key.startsWith(savePrefix)).map((key) => key.slice(savePrefix.length));
    const inventoryItems: T[] = [];

    for (const itemId of itemIds) {
      const item = await getItemAsync(itemId);
      item && inventoryItems.push(item);
    }

    return inventoryItems;
  };

  return {
    saveItemAsync,
    getItemAsync,
    getAllItemsAsync,
    removeItemAsync,
  };
};
