import React from 'react';
import { StyleSheet, View, SafeAreaView } from 'react-native';
import { ListComponent } from '../components/ListComponent';
import { useInventoriesSelector } from '../redux/selectors/inventoriesSelector';
import { ScreenProps } from '../routes';

type Props = ScreenProps<'InventoriesScreen'>;

export const InventoriesScreen: React.FC<Props> = ({ navigation }) => {
  const inventories = useInventoriesSelector();

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <ListComponent
          data={inventories}
          onItemPress={({ id }) => navigation.push('InventoryScreen', { inventoryId: id })}
          onAddNew={() => navigation.push('CreateInventoryScreen')}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    margin: 10,
  },
});
