import React from 'react';
import { CustomPropertyValue } from '../types';
import { CustomPropertyRowRedux } from './CustomPropertyRowRedux';
import { Stack } from './Stack';

type Props = {
  values: [string, CustomPropertyValue][];
};

export const CustomPropertiesList: React.FC<Props> = ({ values }) => {
  return (
    <Stack>
      {values.map(([id, value]) => (
        <CustomPropertyRowRedux key={id} propertyId={id} propertyValue={value} />
      ))}
    </Stack>
  );
};
