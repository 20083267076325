import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CustomProperty } from '../../types';
import { AppState } from '../state';

export const useCustomPropertySelector = (propertyId: string): CustomProperty | undefined =>
  useSelector((state: AppState) => state.customProperties.byId.get(propertyId));

export const useCustomProperties = (): CustomProperty[] => {
  const customPropsMap = useSelector((state: AppState) => state.customProperties.byId);
  return useMemo(() => customPropsMap.valueSeq().toArray(), [customPropsMap]);
};
