import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

type Props = ViewProps & {
  isHorizontal?: boolean;
  children?: React.ReactNode;
};

export const Stack: React.FC<Props> = (props) => {
  const { style, isHorizontal, children } = props;

  return (
    <View {...props} style={[style, isHorizontal ? styles.row : styles.column]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});
