import { nanoid } from 'nanoid';
import React, { useRef, useState } from 'react';
import {
  ImageSourcePropType,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableHighlight,
  View,
} from 'react-native';
import { Button, Icon } from 'react-native-elements';
import { AddPropertySheet } from '../components/AddPropertySheet';
import { ImageSelectorDialog } from '../components/dialogs/ImageSelectorDialog';
import { ItemImage } from '../components/ItemImage';
import { PropertySelectorGrid } from '../components/PropertySelectorGrid';
import { ItemImageList } from '../constants';
import { useCustomProperties } from '../redux/selectors/customPropertiesSelector';
import { useAppDispatch } from '../redux/store';
import { CustomPropertiesThunk } from '../redux/thunks/customPropertiesThunk';
import { ItemsThunk } from '../redux/thunks/itemsThunk';
import { ScreenProps } from '../routes';
import { Colors, GlobalStyles } from '../theme';
import { CustomPropertyValue, Item } from '../types';

type Props = ScreenProps<'CreateItemScreen'>;

export const CreateItemScreen: React.FC<Props> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const [imageSelectorVisible, setImageSelectorVisible] = useState<boolean>(false);
  const [propertyEditorVisible, setPropertyEditorVisible] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>();
  const [itemDescription, setItemDescription] = useState<string>();
  const [itemImage, setItemImage] = useState<ImageSourcePropType>();
  const customProps = useCustomProperties(); //TODO: extract to redux component
  const selectedPropsRef = useRef<Map<string, CustomPropertyValue>>(new Map<string, CustomPropertyValue>());

  const canCreateItem = !!(itemImage && itemName && itemDescription);

  const onCreateItem = () => {
    const properties: [string, CustomPropertyValue][] = [];

    selectedPropsRef.current.forEach((value, key) => {
      properties.push([key, value]);
    });

    if (itemImage && itemName && itemDescription) {
      const newItem: Item = {
        id: nanoid(),
        name: itemName,
        description: itemDescription,
        image: itemImage,
        properties,
      };

      dispatch(ItemsThunk.save(newItem));
      navigation.replace('ItemsScreen');
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          <TouchableHighlight style={styles.imageSelector} onPress={() => setImageSelectorVisible(true)}>
            {itemImage ? <ItemImage source={itemImage} /> : <Text style={styles.imageLabel}>Select an image </Text>}
          </TouchableHighlight>
          <View style={styles.item}>
            <Text style={GlobalStyles.label}>Name</Text>
            <TextInput
              style={GlobalStyles.input}
              onChangeText={(name: string) => setItemName(name)}
              placeholder="Rusted dagger"
            />
          </View>
          <View style={styles.item}>
            <Text style={GlobalStyles.label}>Description</Text>
            <TextInput
              style={GlobalStyles.largeInput}
              onChangeText={(description: string) => setItemDescription(description)}
              multiline={true}
              placeholder="Rusted daggers often used by bandits on a road"
            />
          </View>
          <View style={styles.item}>
            <Text style={GlobalStyles.label}>Properties</Text>
            <PropertySelectorGrid items={customProps} selection={selectedPropsRef.current} />
            <Button
              title="Add new property"
              type="outline"
              containerStyle={styles.addPropertyButton}
              onPress={() => setPropertyEditorVisible(true)}
              icon={<Icon name="add" type="fontawesome" color={Colors.Flame} />}
            />
          </View>
          <View style={styles.item}>
            <Button title="Create" style={styles.createButton} onPress={onCreateItem} disabled={!canCreateItem} />
          </View>
        </View>
      </ScrollView>
      <ImageSelectorDialog
        images={ItemImageList}
        visible={imageSelectorVisible}
        onRequestClose={() => setImageSelectorVisible(false)}
        onImageSelected={(image: ImageSourcePropType) => setItemImage(image)}
      />
      <AddPropertySheet
        visible={propertyEditorVisible}
        onRequestClose={() => setPropertyEditorVisible(false)}
        onPropertyAdded={(prop) => dispatch(CustomPropertiesThunk.save(prop))}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1, //NOTE: needed in web to have scroll
  },
  content: {
    padding: 20,
  },
  item: {
    paddingTop: 20,
  },
  imageSelector: {
    width: 100,
    height: 100,
    borderRadius: 5,
    backgroundColor: Colors.BlackOlive,
    borderWidth: 1,
    borderColor: Colors.FloralWhite,
    borderStyle: 'dashed',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageLabel: {
    color: Colors.FloralWhite,
    textAlign: 'center',
  },
  addPropertyButton: {
    alignSelf: 'flex-end',
    marginTop: 20,
  },
  createButton: {
    marginTop: 20,
  },
});
