import { default as React, useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import { Button, CheckBox } from 'react-native-elements';
import { CustomProperty, CustomPropertyType } from '../../types';
import { ActionSheet } from '../dialogs/ActionSheet/ActionSheet';
import { styles } from './styles';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  onPropertyAdded?: (prop: CustomProperty) => void;
};

export const AddPropertySheet: React.FC<Props> = ({ visible, onRequestClose, onPropertyAdded }) => {
  const [propName, setPropName] = useState<string | undefined>(undefined);
  const [propType, setPropType] = useState<CustomPropertyType | undefined>(undefined);

  const propTypes = Object.keys(CustomPropertyType) as CustomPropertyType[];

  return (
    <ActionSheet visible={visible} onRequestClose={onRequestClose}>
      <View>
        <Text style={styles.label}>Name</Text>
        <TextInput style={styles.input} onChangeText={(name: string) => setPropName(name)} placeholder="Weight" />
        <Text style={styles.label}>Type</Text>
        {propTypes.map((type) => (
          <CheckBox
            key={type}
            title={type}
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            checked={type === propType}
            onPress={() => setPropType(type)}
          />
        ))}
        <Button
          title="Add"
          style={{ marginTop: 20 }}
          onPress={() => {
            onPropertyAdded &&
              propName &&
              propType &&
              onPropertyAdded({
                name: propName,
                type: propType,
              });

            onRequestClose();
          }}
        />
      </View>
    </ActionSheet>
  );
};
