import React from 'react';
import { ImageSourcePropType, ScrollView, StyleSheet, TouchableHighlight, View } from 'react-native';
import { ItemImage } from '../ItemImage';
import { ActionSheet } from './ActionSheet/ActionSheet';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  images: ImageSourcePropType[];
  onImageSelected: (selected: ImageSourcePropType) => void;
};

export const ImageSelectorDialog: React.FC<Props> = ({ images, visible, onRequestClose, onImageSelected }) => (
  <ActionSheet visible={visible} onRequestClose={onRequestClose}>
    <ScrollView horizontal={false} contentContainerStyle={styles.container}>
      <View style={styles.content}>
        {images.map((image, index) => (
          <TouchableHighlight
            key={index}
            onPress={() => {
              onImageSelected(image);
              onRequestClose();
            }}>
            <ItemImage source={image} />
          </TouchableHighlight>
        ))}
      </View>
    </ScrollView>
  </ActionSheet>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
  },
});
