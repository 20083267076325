import * as Immutable from 'immutable';
import { createReducer, describeAction } from 'ts-describe-action';
import { CustomProperty } from '../../types';
import { CustomPropertiesState } from '../state';
import { ActionTypes } from './actionTypes';

const load = describeAction('LOAD_CUSTOM_PROPERTIES', (prev: CustomPropertiesState, properties: CustomProperty[]) => ({
  ...prev,
  byId: Immutable.Map<string, CustomProperty>(properties.map((prop) => [prop.name, prop])),
}));

const save = describeAction('SAVE_CUSTOM_PROPERTY', (prev: CustomPropertiesState, prop: CustomProperty) => {
  return {
    ...prev,
    byId: prev.byId.set(prop.name, prop),
  };
});

const remove = describeAction('REMOVE_CUSTOM_PROPERTY', (prev: CustomPropertiesState, propName: string) => ({
  ...prev,
  byId: prev.byId.remove(propName),
}));

export const CustomPropertiesAction = {
  load,
  save,
  remove,
};

export type CustomPropertiesActionType = ActionTypes<typeof CustomPropertiesAction>;

export const customPropertiesReducer = createReducer(Object.values(CustomPropertiesAction), {
  byId: Immutable.Map<string, CustomProperty>(),
});
