import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useItemByIdSelector } from '../../redux/selectors/itemsSelector';
import { CustomPropertiesList } from '../CustomPropertiesList';
import { ItemImage } from '../ItemImage';
import { ModalDialog } from './ModalDialog';

type Props = {
  visible: boolean;
  onRequestClose: () => void;
  onRemove: () => void;
  itemId?: string;
  removeTitle?: string;
};

export const ItemDescriptionDialog: React.FC<Props> = ({ itemId, visible, removeTitle, onRequestClose, onRemove }) => {
  const item = useItemByIdSelector(itemId); //TODO: do I want to use selectors here?

  return (
    <ModalDialog visible={visible} onRequestClose={onRequestClose}>
      <View style={styles.container}>
        <Text>Item details</Text>
        {item && (
          <View style={styles.content}>
            <ItemImage source={item.image} />
            <Text h4={true}>{item.name}</Text>
            <Text>{item.description}</Text>
            {item.properties && <CustomPropertiesList values={item.properties} />}
          </View>
        )}
        <Button
          title={removeTitle || 'Remove'}
          style={styles.removeButton}
          onPress={() => {
            onRemove();
            onRequestClose();
          }}
        />
      </View>
    </ModalDialog>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 300,
    height: 400,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {},
  removeButton: {
    margin: 10,
  },
});
