import { ImageSourcePropType } from 'react-native';

export type Inventory = {
  readonly id: string;
  readonly name: string;
  readonly image: ImageSourcePropType;
  readonly description?: string;
  readonly slots: Slot[];
};

export enum CustomPropertyType {
  Number = 'Number',
  String = 'String',
}

export type CustomProperty = {
  readonly name: string;
  readonly type: CustomPropertyType;
};

//TODO: пока не понял как это связать с CutomProperty.type на уровне системы типов
export type CustomPropertyValue = string | number | string[];

export type Item = {
  readonly id: string;
  readonly name: string;
  readonly image: ImageSourcePropType;
  readonly description: string;
  readonly properties?: [string, CustomPropertyValue][];
};

export type Slot = { readonly id: string } & (
  | { readonly isEmpty: true }
  | {
      readonly isEmpty: false;
      readonly itemId: string;
      readonly count: number;
    }
);
