import { StyleSheet } from 'react-native';

//https://medium.com/reactbrasil/react-native-set-different-colors-on-top-and-bottom-in-safeareaview-component-f008823483f3
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center', //move inventory to the center
  },
  content: {
    flex: 1, //occupy whole height of screen
    maxWidth: 600,
    // maxHeight: 850,
    width: '100%',
    height: '100%',
    borderWidth: 1,
  },
  removeButton: {
    width: 200,
    margin: 20,
  },
});
