import { useLayout } from '@react-native-community/hooks';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Slot } from '../types';
import { ItemDescriptionDialog } from './dialogs/ItemDescriptionDialog';
import { ItemSlot } from './ItemSlot';

type Props = {
  slots: Slot[];
  itemsPerRow: number;
  onAddNewSlot?: () => void;
  onRemoveSlot?: (slot: Slot) => void;
};

//TODO: add item drag & drop?

export const Inventory: React.FC<Props> = ({ slots, itemsPerRow, onAddNewSlot, onRemoveSlot }) => {
  const layoutInfo = useLayout();
  const imageSize = Math.floor(layoutInfo.width / itemsPerRow);
  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>();

  const selectedItemId = selectedSlot?.isEmpty ? undefined : selectedSlot?.itemId;

  const onSlotPress = (slot: Slot) => {
    if (slot.isEmpty) {
      onAddNewSlot && onAddNewSlot();
    } else {
      setSelectedSlot(slot);
    }
  };

  return (
    <View style={styles.content} onLayout={layoutInfo.onLayout}>
      <FlatList
        numColumns={itemsPerRow}
        data={slots}
        renderItem={({ item: slot }) => (
          <ItemSlot key={slot.id} slot={slot} slotSize={imageSize} onPress={() => onSlotPress(slot)} />
        )}
      />
      <ItemDescriptionDialog
        visible={!!selectedSlot}
        itemId={selectedItemId}
        onRequestClose={() => setSelectedSlot(undefined)}
        onRemove={() => selectedSlot && onRemoveSlot && onRemoveSlot(selectedSlot)}
        removeTitle="Remove from inventory"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1, //occupy whole height of screen
    margin: 10,
  },
});
